import { monitor } from '../tools/monitor';
import { getZoneJsOriginalValue } from '../tools/getZoneJsOriginalValue';
/**
 * Add an event listener to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 */
export function addEventListener(eventTarget, eventName, listener, options) {
  return addEventListeners(eventTarget, [eventName], listener, options);
}
/**
 * Add event listeners to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 *
 * * with `once: true`, the listener will be called at most once, even if different events are listened
 */
export function addEventListeners(eventTarget, eventNames, listener, _a) {
  var _b = _a === void 0 ? {} : _a,
    once = _b.once,
    capture = _b.capture,
    passive = _b.passive;
  var wrappedListener = monitor(once ? function (event) {
    stop();
    listener(event);
  } : listener);
  var options = passive ? {
    capture: capture,
    passive: passive
  } : capture;
  var add = getZoneJsOriginalValue(eventTarget, 'addEventListener');
  eventNames.forEach(function (eventName) {
    return add.call(eventTarget, eventName, wrappedListener, options);
  });
  function stop() {
    var remove = getZoneJsOriginalValue(eventTarget, 'removeEventListener');
    eventNames.forEach(function (eventName) {
      return remove.call(eventTarget, eventName, wrappedListener, options);
    });
  }
  return {
    stop: stop
  };
}
