import { addEventListener } from './addEventListener';
export function runOnReadyState(expectedReadyState, callback) {
  if (document.readyState === expectedReadyState || document.readyState === 'complete') {
    callback();
  } else {
    var eventName = expectedReadyState === 'complete' ? "load" /* DOM_EVENT.LOAD */ : "DOMContentLoaded" /* DOM_EVENT.DOM_CONTENT_LOADED */;
    addEventListener(window, eventName, callback, {
      once: true
    });
  }
}
